import styled from "@emotion/styled";
import colors from "styles/colors";
import { BRAND_BLACK_TEXT_COLOR, WHITE_COLOR_NAME } from "styles/color-consts";
import { LAYER_ONE } from "constants/z-index";
const CARD_BORDER_RADIUS = 8;
export const StyledProductCardGridMobileComponent = styled.div`
  width: 312px;
  height: auto;
  overflow: hidden;
  transition: max-height 0.2s ease-in-out;
  z-index: ${LAYER_ONE};
  margin: 8px;
  padding: 12px;
  border-radius: ${`${CARD_BORDER_RADIUS}px`};
  background-color: ${colors[WHITE_COLOR_NAME]};
  box-shadow: 0 8px 24px rgba(29, 140, 242, 0.16);
  color: ${colors[BRAND_BLACK_TEXT_COLOR]};
  position: relative;

  .card-content {
    margin: 8px;

    .product-card-header {
      margin-bottom: 0;
      position: relative;
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      .product-card-header-content {
        .product-card-header-image {
          height: unset;
          margin-bottom: unset;

          img {
            display: flex;
            max-height: 26px;
          }

          &.is-v2 {
            img {
              max-height: 36px;
            }
          }
        }

        .product-card-header-text {
          font-size: 0.75rem;
          color: ${colors.asphalt};
          padding: 12px 0 0 4px;
        }
      }

      .arrow {
        position: relative;
        transition: transform 0.2s ease-in-out;
        height: 24px;

        &.open {
          transform: rotate(180deg);
        }
      }
    }

    .mobile-product-card-body {
      max-height: 0px;
      transition: max-height 0.15s ease-out;
      overflow: hidden;

      .product-card-body {
        font-weight: 300;
        line-height: 1.5;
        font-size: 0.875rem;
        margin-bottom: 16px;
        margin-top: 12px;
      }

      &.is-v2 {
        .product-card-body {
          font-size: 1.25rem;
          font-weight: 600;
          line-height: 1.3;
        }

        .product-card-use-cases .product-card-use-cases-bullets {
          margin-bottom: 16px;

          .product-card-use-cases-bullet-icon-and-text {
            margin-bottom: 8px;
          }
        }

        .product-card-footer {
          .button {
            .secondary-button {
              .secondary-button-text {
                font-size: 0.8125rem;
              }
            }
          }
        }
      }

      .product-card-use-cases {
        overflow: hidden;

        .product-card-use-cases-title {
          margin-bottom: 16px;
          font-size: 0.8125rem;
          font-weight: 500;
        }

        .product-card-use-cases-bullets {
          .product-card-use-cases-bullet-icon-and-text {
            display: flex;
            flex-direction: row;
            font-size: 0.8125rem;
            margin-bottom: 12px;
            gap: 4px;
            align-items: center;

            .product-card-use-cases-bullet-icon {
              display: flex;
            }
          }
        }
      }

      .product-card-footer {
        .button {
          .signup-combo-buttons-button {
            display: flex;
            flex-direction: row;
            align-items: left;
            margin-top: 12px;

            .signup-button {
              display: none;
            }

            .secondary-button-wrapper {
              margin-top: 0px;
              font-size: 0.281rem;
              margin-left: 0px;

              .secondary-button {
                .secondary-button-text {
                  font-size: 0.8125rem;
                }
              }
            }
          }
        }
      }
    }
  }

  &.open {
    .card-content {
      .mobile-product-card-body {
        max-height: 500px;
        transition: max-height 0.25s ease-in;
      }
    }
  }
`;
