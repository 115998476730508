import { CRM_GREEN_THEME_ID, DEV_GREEN_THEME_ID, WORKOS_IRIS_THEME_ID } from "segments/segments-service";
import { SIGNUP_COMBO_BUTTONS_TYPE } from "segments/desktop/core-components/button/button-consts";
import { BRAND_BLACK_TEXT_COLOR } from "styles/color-consts";
import { CRM_PRODUCT_TYPE, MARKETER_PRODUCT_TYPE, PROJECTS_PRODUCT_TYPE, DEV_PRODUCT_TYPE, WORK_MANAGEMENT_PRODUCT_TYPE, CRM_PRODUCT_ID, DEV_PRODUCT_ID, WORK_MANAGEMENT_PRODUCT_ID } from "constants/products";
import WM_LOGOS from "constants/work-managment-logos";
import { CRM, SOFTWARE } from "constants/clusters";
import { WORK_MANAGEMENT_MINI_SITE_LINK, MARKETING_MANAGEMENT_MINI_SITE_LINK, CRM_MINI_SITE_LINK, PROJECT_MANAGEMENT_MINI_SITE_LINK, SOFTWARE_MINI_SITE_LINK } from "constants/links";
export const productCardsImages = {
    [WORK_MANAGEMENT_PRODUCT_TYPE]: {
        url: WORK_MANAGEMENT_MINI_SITE_LINK,
        image: {
            src: WM_LOGOS.WM_MAIN_LOGO,
            alt: "monday work-management logo"
        },
        mobileImage: {
            src: WM_LOGOS.WM_MOBILE_1_LOGO,
            alt: "monday work-management logo"
        },
        mobileImageV2: {
            src: "https://dapulse-res.cloudinary.com/image/upload/remote_mondaycom_static/uploads/Yotam_Ron/local-mobile/WM-card-logo-v2.png",
            alt: "monday work-management logo"
        },
        mediumScreenImage: {
            src: "https://dapulse-res.cloudinary.com/image/upload/Generator_featured%20images/Home%20Page%20-%202022%20Rebrand/products/medium_size/wm_text.png",
            alt: "work management title"
        }
    },
    [MARKETER_PRODUCT_TYPE]: {
        url: MARKETING_MANAGEMENT_MINI_SITE_LINK,
        image: {
            src: "https://dapulse-res.cloudinary.com/image/upload/Generator_featured%20images/Home%20Page%20-%202022%20Rebrand/products/marketer_main_logo.png",
            alt: "monday marketer logo"
        },
        mobileImage: {
            src: "https://dapulse-res.cloudinary.com/image/upload/Generator_featured%20images/Home%20Page%20-%202022%20Rebrand/products/Mobile/Marketer1.png",
            alt: "monday marketer logo"
        },
        mediumScreenImage: {
            src: "https://dapulse-res.cloudinary.com/image/upload/Generator_featured%20images/Home%20Page%20-%202022%20Rebrand/products/medium_size/marketer_text.png",
            alt: "monday marketer title"
        },
        mobileImageV2: {}
    },
    [CRM_PRODUCT_TYPE]: {
        url: CRM_MINI_SITE_LINK,
        image: {
            src: "https://dapulse-res.cloudinary.com/image/upload/Generator_featured%20images/Home%20Page%20-%202022%20Rebrand/products/crm_main_logo.png",
            alt: "monday CRM logo"
        },
        mobileImage: {
            src: "https://dapulse-res.cloudinary.com/image/upload/Generator_featured%20images/Home%20Page%20-%202022%20Rebrand/products/Mobile/Sales_CRM1.png",
            alt: "monday CRM logo"
        },
        mobileImageV2: {
            src: "https://dapulse-res.cloudinary.com/image/upload/remote_mondaycom_static/uploads/Yotam_Ron/local-mobile/CRM-card-logo-v2.png",
            alt: "monday CRM logo"
        },
        mediumScreenImage: {
            src: "https://dapulse-res.cloudinary.com/image/upload/Generator_featured%20images/Home%20Page%20-%202022%20Rebrand/products/medium_size/crm_text.png",
            alt: "monday sales title"
        }
    },
    [PROJECTS_PRODUCT_TYPE]: {
        url: PROJECT_MANAGEMENT_MINI_SITE_LINK,
        image: {
            src: "https://dapulse-res.cloudinary.com/image/upload/Generator_featured%20images/Home%20Page%20-%202022%20Rebrand/products/projects_main_logo.png",
            alt: "monday projects logo"
        },
        mobileImage: {
            src: "https://dapulse-res.cloudinary.com/image/upload/Generator_featured%20images/Home%20Page%20-%202022%20Rebrand/products/Mobile/Project1.png",
            alt: "monday projects logo"
        },
        mediumScreenImage: {
            src: "https://dapulse-res.cloudinary.com/image/upload/Generator_featured%20images/Home%20Page%20-%202022%20Rebrand/products/medium_size/projects_text.png",
            alt: "monday projects title"
        },
        mobileImageV2: {}
    },
    [DEV_PRODUCT_TYPE]: {
        url: SOFTWARE_MINI_SITE_LINK,
        image: {
            src: "https://dapulse-res.cloudinary.com/image/upload/Generator_featured%20images/Home%20Page%20-%202022%20Rebrand/Dev_new_logo_July23/dev_main_logo1.png",
            alt: "monday dev logo"
        },
        mobileImage: {
            src: "https://dapulse-res.cloudinary.com/image/upload/Generator_featured%20images/Home%20Page%20-%202022%20Rebrand/products/Mobile/Dev1.png",
            alt: "monday dev logo"
        },
        mobileImageV2: {
            src: "https://dapulse-res.cloudinary.com/image/upload/remote_mondaycom_static/uploads/Yotam_Ron/local-mobile/DEV-card-logo-v2.png",
            alt: "monday dev logo"
        },
        mediumScreenImage: {
            src: "https://dapulse-res.cloudinary.com/image/upload/Generator_featured%20images/Home%20Page%20-%202022%20Rebrand/products/medium_size/dev_text.png",
            alt: "monday dev title"
        }
    }
};
export const productIndexToProductType = {
    0: WORK_MANAGEMENT_PRODUCT_TYPE,
    1: MARKETER_PRODUCT_TYPE,
    2: CRM_PRODUCT_TYPE,
    3: PROJECTS_PRODUCT_TYPE,
    4: DEV_PRODUCT_TYPE
};
export default {
    cards: [
        {
            showOpen: true,
            isHighlighted: false,
            productType: WORK_MANAGEMENT_PRODUCT_TYPE,
            theme: WORKOS_IRIS_THEME_ID,
            header: {
                subtitleText: "For professionals and teams managing tasks & workflows"
            },
            body: {
                bodyText: "Manage tasks, projects, and processes to fuel collaboration and efficiency at scale."
            },
            showDivider: true,
            useCases: {
                text: "Top use cases:",
                list: [
                    {
                        text: "Project management"
                    },
                    {
                        text: "Portfolio management"
                    },
                    {
                        text: "Resource management"
                    },
                    {
                        text: "Goals & strategy"
                    }
                ]
            },
            buttonConfig: {
                buttonType: SIGNUP_COMBO_BUTTONS_TYPE,
                buttonText: "Learn more",
                isOpenLinkInNewTab: true,
                color: BRAND_BLACK_TEXT_COLOR,
                signupButtonConfig: {
                    buttonColor: BRAND_BLACK_TEXT_COLOR,
                    signupProductId: WORK_MANAGEMENT_PRODUCT_ID
                },
                secondaryButtonConfig: {
                    buttonText: "Learn more",
                    url: WORK_MANAGEMENT_MINI_SITE_LINK,
                    color: BRAND_BLACK_TEXT_COLOR,
                    isOpenLinkInNewTab: true
                }
            }
        },
        {
            showOpen: false,
            isHighlighted: false,
            productType: CRM_PRODUCT_TYPE,
            theme: CRM_GREEN_THEME_ID,
            header: {
                subtitleText: "For sales professionals and customer-facing teams"
            },
            body: {
                bodyText: "Track and manage all aspects of your sales cycle, customer data, and more in one place."
            },
            showDivider: true,
            useCases: {
                text: "Top use cases:",
                list: [
                    {
                        text: "Contact management"
                    },
                    {
                        text: "Sales pipeline"
                    },
                    {
                        text: "Post-sales management"
                    },
                    {
                        text: "Lead management"
                    }
                ]
            },
            buttonConfig: {
                buttonType: SIGNUP_COMBO_BUTTONS_TYPE,
                buttonText: "Learn more",
                isOpenLinkInNewTab: true,
                color: BRAND_BLACK_TEXT_COLOR,
                url: "https://monday.com/crm",
                signupButtonConfig: {
                    buttonColor: BRAND_BLACK_TEXT_COLOR,
                    signupProductId: CRM_PRODUCT_ID,
                    signupClusterId: CRM
                },
                secondaryButtonConfig: {
                    buttonText: "Learn more",
                    url: CRM_MINI_SITE_LINK,
                    color: BRAND_BLACK_TEXT_COLOR,
                    isOpenLinkInNewTab: true
                }
            }
        },
        {
            showOpen: false,
            isHighlighted: false,
            productType: DEV_PRODUCT_TYPE,
            theme: DEV_GREEN_THEME_ID,
            header: {
                subtitleText: "For product and development professionals and teams"
            },
            body: {
                bodyText: "Build agile workflows to drive impact across your product, design, and R&D teams."
            },
            showDivider: true,
            useCases: {
                text: "Top use cases:",
                list: [
                    {
                        text: "Roadmap planning"
                    },
                    {
                        text: "Scrum & Kanban"
                    },
                    {
                        text: "Bug tracking"
                    },
                    {
                        text: "Sprint dashboards"
                    }
                ]
            },
            buttonConfig: {
                buttonType: SIGNUP_COMBO_BUTTONS_TYPE,
                buttonText: "Learn more",
                isOpenLinkInNewTab: true,
                color: BRAND_BLACK_TEXT_COLOR,
                url: "https://monday.com/dev",
                signupButtonConfig: {
                    buttonColor: BRAND_BLACK_TEXT_COLOR,
                    signupProductId: DEV_PRODUCT_ID,
                    signupClusterId: SOFTWARE
                },
                secondaryButtonConfig: {
                    buttonText: "Learn more",
                    url: SOFTWARE_MINI_SITE_LINK,
                    color: BRAND_BLACK_TEXT_COLOR,
                    isOpenLinkInNewTab: true
                }
            }
        }
    ]
};
