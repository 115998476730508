import styled from "@emotion/styled";
import { TABLET_QUERY_MAX_WIDTH } from "constants/vp-sizes";
export const StyledProductCardsGridComponent = styled.div`
  .product-cards-grid-full-size-wrapper {
    display: unset;
  }

  .product-cards-grid-small-size-wrapper {
    display: none;
  }

  @media (max-width: ${TABLET_QUERY_MAX_WIDTH}) {
    .product-cards-grid-full-size-wrapper {
      display: none;
    }

    .product-cards-grid-small-size-wrapper {
      display: unset;
    }
  }
`;
