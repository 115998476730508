import { CRM_PRODUCT_TYPE, DEV_PRODUCT_TYPE, WORK_MANAGEMENT_PRODUCT_TYPE } from "constants/products";
import { CRM_PRODUCT_ID, DEV_PRODUCT_ID, WORK_MANAGEMENT_PRODUCT_ID } from "constants/products";
import { WORK_OS_IRIS_COLOR_NAME, DEV_DARKER_TINT_02_COLOR_NAME, CRM_DARKER_TINT_02_COLOR_NAME } from "styles/color-consts";
export const BUTTON_COLOR_BY_PRODUCT = {
    [WORK_MANAGEMENT_PRODUCT_TYPE]: WORK_OS_IRIS_COLOR_NAME,
    [CRM_PRODUCT_TYPE]: CRM_DARKER_TINT_02_COLOR_NAME,
    [DEV_PRODUCT_TYPE]: DEV_DARKER_TINT_02_COLOR_NAME
};
export const SIGNUP_ID_BY_PRODUCT = {
    [WORK_MANAGEMENT_PRODUCT_TYPE]: WORK_MANAGEMENT_PRODUCT_ID,
    [CRM_PRODUCT_TYPE]: CRM_PRODUCT_ID,
    [DEV_PRODUCT_TYPE]: DEV_PRODUCT_ID
};
